@import "../UI/styling/variables.css";

.container {
  max-width: var(--max-width);
  margin: 0 auto;
}

.container .reviews__title {
  font-size: 3.3rem;
  text-align: center;
  font-family: var(--shrikhand);
  margin-bottom: 1rem;
  /* border: 1px solid red; */
}

.school__box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.school__box > * {
  position: relative;
  margin: 1rem;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  height: 37rem;
  border-radius: var(--border-radius);
  overflow: hidden;
  flex: 3 0 42rem;
}

.school__name {
  display: flex;
  margin-bottom: 0.3rem;
}

.school__name h2 {
  font-size: 1.7rem;
}

.building__icon {
  margin-right: 0.5rem;
  font-size: 2.5rem;
}

.address {
  font-family: var(--sans-pro);
}

.average {
  position: absolute;
  bottom: 0;
  right: 10px;
}

.box .review {
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  font-size: var(--paragraph);
  max-height: 20rem;
  padding: 1rem 1.5rem;
  overflow-y: scroll;
}

.rating__container {
  display: flex;
  align-items: center;
}

.rating__container h4 {
  padding: 0;
  margin: 0;
}
