@import "./variables.css";

.main__boxes--container {
  position: relative;
  top: 0px;
  width: 100%;
}

.boxes__container {
  position: relative;
  margin: 0 auto 5rem auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  min-height: 500px;
  height: 100%;
  background-color: transparent !important;
}

.box {
  text-align: center;
  width: 350px;
  /* width: 100%; */
  height: 300px;
  margin: 4rem 5rem;
  /* border: 1px solid red; */
}

.box img {
  height: 100%;
}

.box h2 {
  font-size: var(--h2);
  font-family: var(--poppins);
  font-weight: 700;
  font-style: italic;
  background-color: transparent;
}

@media only screen and (max-width: 1100px) {
  .box {
    margin: 5rem 2rem;
  }
}
