@import "./variables.css";

footer {
}

.footer__container {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 250px;
}

.footer__container h1 {
  font-size: var(--logo-fontSize);
  font-family: var(--anybody);
  font-weight: 700;
  font-style: italic;
}

.footer__container p {
  font-size: 1.5rem;
  color: var(--darkmode-navy);
}

.footer__container--menu {
  display: flex;
}

.menu__box {
  margin: 0 1rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu__box a {
  margin: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--darkmode-navy);
}
