@import "../../UI/styling/variables.css";

.addreview__container {
  max-width: 1500px;
  margin: 5rem auto 0 auto;
  min-height: 60vh;
  padding: 1rem;
}

.bg__img {
  min-height: 30vh;
  background-image: url("../../assets/classrobot.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.bg__img h1,
.bg__img h3 {
  color: white;
  text-align: center;
}

.bg__img h1 {
  font-size: 3rem;
  font-weight: 700;
}

.bg__img h3 {
  font-size: 1.8rem;
}

form h2 {
  text-align: center;
  font-family: var(--shrikhand);
  font-size: 3rem;
  margin: 2rem 0;
}

.topinputs__container {
  display: flex;
  flex-wrap: wrap;
}

.input__titles {
  font-size: 1.6rem;
  font-weight: 500;
}

.topinputs__container > * {
  flex: 1 5 33rem;
  padding: 1rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
}

.topinputs__container > *,
.checkbox__container {
  border: 1px solid rgba(128, 128, 128, 0.315);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.checkbox__container {
  display: inline-flex;
  align-items: center;
  padding: 0.3rem 1rem 0.3rem 0.6rem;
  margin: 0.2rem 0.3rem 0.2rem 0;
  font-size: 1.6rem;
  border-radius: var(--border-radius);
}

.label__text {
  display: flex;
  align-items: center;
}

.label__text,
.select__menu {
  font-size: 1.4rem;
}

.topinputs__container > * input {
  margin: 0.5rem;
}

.checkboxes {
  padding: 1rem;
}

.textarea__container {
  position: relative;
  max-width: 90rem;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.textarea__container h3 {
  font-size: 2rem;
}

.textarea__container textarea {
  resize: none;
  width: 100%;
  height: 150px;
  background-color: transparent;
  font-size: 1.7rem;
  border-radius: var(--border-radius);
  border: 2px solid rgba(194, 194, 194, 0.274);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
}

.textarea__container .word__count {
  font-style: italic;
  font-weight: 600;
  font-size: 1.3rem;
}

select {
  width: 70%;
  padding: 0.5rem;
}

.checkbox {
  margin: 0.3rem 0.3rem 0.3rem 0;
  transform: scale(0.8);
  transition: transform 300ms ease;
}

.checkbox:checked {
  transform: scale(1.1);
}

.submit__btn {
  margin: 1rem auto 0 auto;
  padding: 0.3rem 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  border: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-radius);
  color: var(--darkmode-navy);
}

.checkbox__error {
  text-align: center;
  width: 21rem;
}

.starrating__error {
  width: 14.5rem;
}

.classof__error {
  margin-top: 1rem;
}

.checkbox__error,
.starrating__error,
.classof__error {
  text-align: center;
  padding: 0.5rem;
  background-color: rgb(255, 175, 175);
  border-radius: var(--border-radius);
  font-size: 1.5rem;
}
