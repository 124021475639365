@import "../styling/variables.css";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal {
  padding: 1rem;
  /* background-color: white; */
  position: fixed;
  top: 20vh;
  left: 0%;
  right: 0;
  margin: auto;
  max-width: 400px;
  width: 100%;
  z-index: 9999999;
  border-radius: var(--border-radius);
}
