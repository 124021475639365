@import "./Components/UI/styling/variables.css";

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: var(--poppins);
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1 rem === 10px */
}

@media only screen and (max-width: 1100px) {
  html {
    font-size: 50%;
  }
}

body {
  background-color: var(--white);
  background-color: var(--darkmode-navy);
}

.App {
  min-height: 100vh;
  margin: 0 auto;
}

.dark {
  color: white;
  background-color: red;
  background-color: var(--darkmode-navy);
}

.light {
  color: var(--darkmode-navy);
  background-color: var(--white);
}
