@import "./variables.css";

.error__container {
  margin: 0 auto;
  max-width: var(--max-width);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
  /* border: 1px solid red; */
}

.error__container--text {
  text-align: center;
  margin-top: 5rem;
  /* border: 2px solid red; */
  width: 50%;
}

.error__container--text h2 {
  font-family: var(--shrikhand);
  font-size: 7rem;
}

.error__container--icon img {
  padding: 2rem;
  max-width: 70rem;
  margin: 0 auto;
  width: 100%;
}

.back__btn {
  position: absolute;
  top: 75px;
  left: 15px;
  padding: 0.2rem 1rem;
  border: 2px solid;
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 1416px) {
  .error__container--text {
    width: 100%;
  }

  .error__container--icon {
    text-align: center;
    width: 100%;
  }

  
}
