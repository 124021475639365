@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shrikhand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anybody:ital,wght@1,700&display=swap");

:root {
  /* COLORS */
  --max-width: 1800px;
  /* --max-navbar-mainsection-width: 1800px; */
  --white: #fefff1;
  --teal: #00c49a;
  --teal-active: #00b991;
  --yellow: #f4e285;
  --yellow: #f5d848;
  /* --darkmode-navy: #071013; */
  --darkmode-navy: #12242b;
  --reviewbox-navy: #253942;
  --reviewbox-white: #fffffb;
  --error-red: #ff4646;
  /* --button-blue: #2196f3; */
  /* --button-blue-clicked: #1e88e5; */
  --section-teal: #caffde;
  --section-yellow: #f1dd78;

  --logo-fontSize: 3rem;
  --paragraph: 1.5rem;
  --h2: 2.5rem;

  --box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

  --border-radius: 2px;
  --btn-padding: 0.3rem 1rem;
  --sans-pro: "Source Sans Pro", sans-serif;
  --shrikhand: "Shrikhand", "Poppins", Arial, cursive;
  --poppins: "Poppins", sans-serif;
  --anybody: "Anybody", "Poppins", Arial, cursive;
}

/* yellow FFDC2F */

.active {
  border-bottom: 2px solid !important;
}

@media only screen and (max-width: 767px) {
  .active {
    border: none !important;
  }
}
