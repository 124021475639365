@import "./variables.css";

.main__section--container {
  position: relative;
  top: 35px;
  max-width: var(--max-navbar-mainsection-width);
  width: 100%;
  margin: 0 auto 3.5rem auto;
  display: flex;
  flex-wrap: wrap-reverse;
  min-height: 50vh;
}

.main__section--h1 {
  text-align: center;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 3.5rem;
}

.left__box {
  padding: 4rem 1rem 1rem 1rem;
  max-width: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.left__box img {
  max-width: 200px;
  width: 100%;
  padding: 2rem;
}

.left__box--text {
  text-align: center;
  max-width: 450px;
}

.left__box--text .discover {
  font-size: 6rem;
  font-family: var(--shrikhand);
}

.left__box--text p {
  padding: 0 1rem;
  font-size: 1.6rem;
  font-weight: 500;
}

.right__box {
  padding: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  width: 100%;
  min-height: 100%;
}

.right__box--text {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 100px;
  width: 100%;
}

.right__box--text h2 {
  font-size: var(--h2);
  color: var(--teal);
  font-family: var(--poppins);
  font-style: italic;
  font-weight: 900;
}

.input__container {
  width: 70%;
  position: relative;
}

.input {
  padding: 0.4rem 25px 0.4rem 35px;
  border: 1px solid rgb(152, 152, 152);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius);
  font-size: 1.6rem;
  width: 100%;
  height: 35px;
}

.input:focus {
  outline: none;
  border: 1px solid rgb(152, 152, 152);
}

.input__container img {
  position: absolute;
  left: 10px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.input__container .results {
  overflow: hidden;
  overflow-y: auto;
  max-height: 150px;
  position: absolute;
  width: 100%;
  text-align: left;
  background-color: white;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.input__container .results a {
  display: block;
}

.input__container .results::-webkit-scrollbar {
  display: none;
}

.input__container .results > * {
  margin: 0.4rem 0 0.4rem 0;
  padding-left: 1rem;
  font-size: 1.6rem;
  color: var(--darkmode-navy);
}

.input__container .results > *:hover {
  background-color: rgb(228, 228, 228);
  cursor: pointer;
}

.input__container .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  color: rgb(192, 192, 192);
  font-size: 3rem;
  cursor: pointer;
}

.add__school {
  color: rgb(38, 150, 255) !important;
}

@media only screen and (max-width: 1100px) {
  .main__section--container {
    flex-direction: column;
    justify-content: center;
    top: 0;
    margin: 0;
    padding: 0;
  }

  .left__box {
    max-width: 100%;
    padding: 5rem 0 0 0;
  }

  .right__box {
    min-height: 100%;
    height: 100%;
    max-width: 100%;
    padding: 3rem 0;
  }

  .input__container {
    width: 90%;
  }

  .input {
    height: 30px;
  }
}
