@import "../../UI/styling/variables.css";

.details__container {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
  padding: 1rem;
  min-height: 100vh;
}

.details__header {
  margin-top: 5rem;
}

.details__header .address {
  font-size: 1.7rem;
}

.details__header .school__name {
  font-weight: 600;
  font-size: 3rem;
}

.details__header .review__plusicon {
  font-size: 2rem;
  margin-left: 0.5rem;
  color: rgb(61, 61, 61);
}

/* THE ENTIRE REVIEW SECTION CONTAINER */
.main__content {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
}

.filter__container {
  position: sticky;
  height: 50%;
  top: 7rem;
  border-radius: var(--border-radius);
  outline: 2px solid rgba(216, 216, 216, 0.5);
  min-width: 180px;
  padding: 0.5rem;
  flex: 0 0 10rem;
}

.filter__container h2 {
  font-size: 2rem;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
}

.filter__container h2 img {
  margin-right: 0.2rem;
  width: 3.2rem;
}

.filters {
  height: 600px;
}

.filters .starfilter {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.filters .starfilter h3,
.filters .recommended h3 {
  font-size: var(--paragraph);
}

.checkbox {
  display: flex;
  align-items: center;
  padding: 0.3rem;
}

.checkbox label {
  font-size: 1.2rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.filters .recommended {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}

.reviews__section {
  margin-left: 1rem;
  width: 100%;
  flex: 3 1 80rem;
}

.review__container {
  position: relative;
  min-height: 200px;
  box-shadow: var(--box-shadow);
  margin-bottom: 1rem;
  padding: 1rem 1rem;
}

.date__posted {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.3rem;
  font-weight: 600;
  opacity: 0.7;
}

.classof {
  position: absolute;
  right: 10px;
  bottom: 5px;
  display: flex;
  align-items: center;
}

.classof img {
  width: 2.6rem;
  margin-left: 0.5rem;
}

.classof h3 {
  margin: 0;
  font-size: var(--paragraph);
}

.review {
  padding: 0.5rem 0 2rem 0;
  font-size: var(--paragraph);
}

.wouldRecommend {
  position: absolute;
  left: 10px;
  bottom: 5px;
  display: flex;
  align-items: center;
}

.wouldRecommend p {
  font-size: var(--paragraph);
  margin: 0 0 0 0.5rem;
}

.wouldRecommend img {
  width: 2.4rem;
}

.tags {
  display: flex;
}

.tags > * {
  border: 1px solid;
  padding: 0.3rem 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: var(--border-radius);
  margin-right: 1rem;
}

.stars {
  font-size: 2rem;
}

.norating {
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.norating .cat {
  margin: 1rem;
  max-width: 20rem;
}

.norating p {
  font-family: var(--shrikhand);
  font-size: 4rem;
}

.paginationbtn__container {
  text-align: center;
}

.pagination__btn {
  border-radius: var(--border-radius);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border: none;
  width: 200px;
  padding: 0.3rem 1rem;
  font-size: 1.8rem;
}

.back__btn {
  font-size: 1.6rem;
  border-radius: var(--border-radius);
  margin: 2rem 1rem 1rem 0;
  padding: 0.5rem;
  /* max-width: 7.5rem; */
  /* border: 1px solid red; */
  /* display: block; */
}

.header a {
  width: 8rem;
  display: inline-block;
}

.header {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 880px) {
  .filter__container {
    position: relative;
    top: 0;
    min-width: 100%;
    padding: 0.5rem;
    margin: 0 0 1rem 0rem;
  }

  .reviews__section {
    margin: 0;
  }

  .filters {
    display: flex;
  }

  .filters .starfilter,
  .filters .recommended {
    margin: 0;
    padding: 1rem;
  }

  .filters.open {
    height: 170px;
  }

  .filters.closed {
    display: none;
  }
}
