@import "../styling/variables.css";

.nav__container {
  padding: 0 2rem;
  /* box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  webkit-box-shadow: 0 8px 6px -3px rgba(0, 0, 0, 0.1);
  moz-box-shadow: 0 8px 6px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 6px -3px rgba(0, 0, 0, 0.1); */
  min-width: 100%;
}

.dark {
  color: var(--yellow);
}

.light {
  color: var(--darkmode-navy);
}

.items,
.sub__items {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
}

.navbar__container {
  border: 1px solid transparent;
}

.navlinks > * {
  margin: 0;
  padding: 0;
  border-bottom: 2px solid transparent;
}

.navlinks > *:hover {
  border-radius: var(--border-radius);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .navlinks > * {
    font-size: 2rem;
  }
}
